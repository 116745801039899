import { JsonApiData } from '@/jsonApi.types';
import { Month } from '@/common/types/dateTime.types';
import { Plan } from '../../merchant/views/plans/plans.types';
import { BillingRecurrenceModel } from './billingRecurrences.types';

export enum CreditMethod {
  AUTH_AND_CAPTURE = 'capture',
  AUTH_ONLY = 'auth',
}

export enum CardChargeType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum PaymentTypeDisplay {
  CREDIT = 'Credit / Debit Card',
  ACH = 'Electronic Check(PAYA)',
  KEYBANK = 'Electronic Check(KEYBANK)',
  HUNTINGTON = 'Electronic Check(HUNTINGTON)',
  CASH = 'Cash',
}

export enum PaymentType {
  CREDIT = 'credit',
  ACH = 'ach',
  CASH = 'cash',
  KEYBANK = 'keybank',
  HUNTINGTON = 'huntington',
}

export enum AchAccountType {
  CHECKING = 'checking',
  SAVINGS = 'savings',
}

export enum AchAccountHolderType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export enum AchTransactionType {
  TEL = 'tel',
  WEB = 'web',
  PPD = 'ppd',
}

export enum KeyBankTransactionType {
  TEL = 'tel',
  WEB = 'web',
  PPD = 'ppd',
}

export enum HuntingtonTransactionType {
  TEL = 'tel',
  WEB = 'web',
  PPD = 'ppd',
  CCD = 'ccd',
}

export enum AuthorizationType {
  INPERSON = 'In person',
  ONPHONE = 'On phone',
}

export interface CreditCard {
  cardNumber?: string;
  expMonth: number;
  expYear: Month;
  cvv?: string;
  method?: CreditMethod;
  isInUse?: boolean;
}

export interface PaymentMethod extends JsonApiData {
  [index: string]: any | null;
  type: PaymentType;
  creditCard?: CreditCard;
  ach?: Ach;
  keybank?: Keybank;
  huntington?: Huntington;
  processorId?: string;
  hostedPaymentPageId?: string;
  processorName?: string;
  customerId?: string;
  nickname: string;
  isDefault?: boolean;
  surchargeAmount?: number;
  key?: string;
}

export interface VaultCreditCard {
  method: CreditMethod;
}

export interface VaultPaymentMethod {
  creditCard?: VaultCreditCard;
  id: string;
  customerId: string;
  isCof: string;
  processorId?: string;
  type?: string;
}

export interface CardBrand {
  name: string;
  regex: RegExp;
  icon: string[];
}

export interface Ach {
  routingNumber: string;
  accountNumber: string;
  accountHolderType: AchAccountHolderType;
  accountType: AchAccountType;
  industry?: string;
}

export interface Keybank {
  routingNumber: string;
  accountNumber: string;
  accountHolderType: AchAccountHolderType;
  accountType: AchAccountType;
  industry: string;
}

export interface Huntington {
  routingNumber: string;
  accountNumber: string;
  accountHolderType: AchAccountHolderType;
  accountType: AchAccountType;
  industry: string;
}

export interface RecurringPayment {
  name: string;
  description: string;
  plan: Plan | null;
  billingRecurrence: BillingRecurrenceModel;
}
